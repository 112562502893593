import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCy40Fn0ZVJvTzh2chfDDn_vg7-5DDhBPs',
  authDomain: 'copenhagen-residences.firebaseapp.com',
  projectId: 'copenhagen-residences',
  storageBucket: 'copenhagen-residences.appspot.com',
  messagingSenderId: '1065684626994',
  appId: '1:1065684626994:web:2a09abf89e387c98d7162b',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const storage = getStorage(app);

export const uploadFile = async (file: File, fileName: string) => {
  const snapshot = await uploadBytes(ref(storage, `images/${fileName}`), file);
  return getDownloadURL(snapshot.ref);
};

// eslint-disable-next-line
export const deleteFiles = async (files?: Array<any>) => {
  const promises = [];
  if (files && files.length) {
    for (const file of files) {
      promises.push(deleteObject(ref(storage, `images/${file.name}`)));
    }
  }

  await Promise.all(promises);
};

export const deleteFile = async (fileName: string) => {
  await deleteObject(ref(storage, `images/${fileName}`));
};
